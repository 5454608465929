import { getField, updateField } from 'vuex-map-fields';
import Vue from 'vue';
import VehicleService from '../../services/VehicleService';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = () => ({
    VehicleContracts: null,
    OwnerContracts: null,
    IsActive: null,
    IsMine: null,
    Id: null,
    CreateDate: null,
    Manufacturer: null,
    Ownership: null,
    PlateNumber: null,
    Model: null,
    Year: null,
    Color: null,
    VehicleType: null,
});

const getters = {
    getField,
    form: (state) => ({
        Manufacturer: state.Manufacturer,
        Ownership: state.Ownership,
        PlateNumber: state.PlateNumber,
        CreateDate: state.CreateDate,
        Model: state.Model,
        Year: state.Year,
        Color: state.Color,
        VehicleType: state.VehicleType,
    }),
};

const mutations = {
    setVehicle(state, data) {
        Object.assign(state, data);
    },

    setVehicleContracts(state, data) {
        Vue.set(state, 'VehicleContracts', [...data]);
    },
    setOwnerContracts(state, data) {
        Vue.set(state, 'OwnerContracts', [...data]);
    },

    storeVehicleDetails(state, data) {
        Object.assign(state, data);
    },
    updateField,
};

const actions = {
    async loadVehicle({ commit }, data) {
        const res = await VehicleService.getVehicle(data.id);
        commit('setVehicle', res.data);
    },
    async loadVehicleAdmin({ commit }, data) {
        const res = await VehicleService.getVehicleAdmin(data.id);
        commit('setVehicle', res.data);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
