import { getField, updateField } from 'vuex-map-fields';
import LessonService from '@/services/LessonService';

const getDefaultState = function () {
    return {
        id: null,
        name: null,
        sortOrder: null,
        link: null,
        lawCollectionName: null,
        verdictCollectionName: null,
        category: null,
        categoryId: null,
        verdictCollectionId: null,
        lawCollectionId: null,
        videoCategory: null,
        status: null,
        isInteractiveVideo: null,
    };
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = getDefaultState();

const getters = {
    getField,
    form: (state) => ({
        id: state.id,
        name: state.name,
        sortOrder: state.sortOrder,
        link: state.link,
        lawCollectionName: state.lawCollectionName,
        verdictCollectionName: state.verdictCollectionName,
        category: state.category,
        categoryId: state.categoryId,
        verdictCollectionId: state.verdictCollectionId,
        lawCollectionId: state.lawCollectionId,
        videoCategory: state.videoCategory,
        status: state.status,
        isInteractiveVideo: state.isInteractiveVideo,
    }),
};

const mutations = {
    setVideo(state, value) {
        if (value) {
            // value.Transfers.forEach((status) => {
            //     status.Events.sort((a, b) => new Date(a.Date) - new Date(b.Date));
            // });
            Object.assign(state, value);
        } else {
            Object.assign(state, getDefaultState());
        }
    },

    storeReportDetails(state, data) {
        Object.assign(state, data);
    },
    updateField,
};

const actions = {
    async loadVideo({ commit }, data) {
        const course = JSON.parse(localStorage.getItem('course'));
        const res = await LessonService.getAdminVideos(course.id, {
            EntityId: data.id
        });
        commit('setVideo', res.data.items[0]);
        return res.data;
    },
    async loadVideoAdmin({ commit }, data) {
        const res = await LessonService.getReportAdmin(data.id);
        commit('setVideo', res.data);
        return res.data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
