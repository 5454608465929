/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const state = () => ({
    list: [],
});

const getters = {

};

const mutations = {
    setList(state, tabs) {
        state.list = tabs;
    },
};

const actions = {
    setList({ commit }, tabs) {
        commit('setList', tabs);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
