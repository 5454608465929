import { getField, updateField } from 'vuex-map-fields';
import AccidentService from '../../services/AccidentService';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = () => ({
    CreateDate: null,
    Vehicle: null,
    Id: null,
    PdfUrl: null,
    PdfStatus: null,
    IsClosed: null,
    Date: null,
    Address: null,
    PlateNumber: null,
    Description: null,
    AccidentType: null,
    MyVehicle: null,
    OtherVehicle: null,
});

const getters = {
    getField,
    form: (state) => ({
        AccidentType: state.AccidentType,
        Address: state.Address,
        Date: state.Date,
        Description: state.Description,
        PlateNumber: state.PlateNumber,
    }),
};

const mutations = {
    setData(state, data) {
        Object.assign(state, data);
    },
    updateField,
};

const actions = {
    async load({ commit }, data) {
        const res = await AccidentService.get(data.id);
        commit('setData', res.data);
        return res.data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
