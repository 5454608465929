import { ToastProgrammatic as Toast } from 'buefy';
import axios from 'axios';
import router from './router';
import store from './store';

let isAlreadyFetchingAccessToken = false;
let error401Retries = 0;
const maxError401Retries = 4;
let subscribers = [];

const onAccessTokenFetched = (accessToken) => subscribers = subscribers.filter((callback) => callback(accessToken));

const addSubscriber = (callback) => subscribers.push(callback);

const preventRetryOriginalRequest = (originalRequest) => {
  const { url } = originalRequest;
  return url.includes('ExternalUser');
};

axios.interceptors.response.use((response) => response, (error) => {
  const { config, response: { status } } = error;
  const originalRequest = config;

  if (status === 401) {
    if (error401Retries === maxError401Retries) {
      error401Retries = 0;
      return Promise.reject(error);
    }
    error401Retries += 1;

    if (preventRetryOriginalRequest(originalRequest)) {
      return Toast.open({
        type: 'is-warning',
        message: 'נתונים לא נכונים',
        position: 'is-bottom',
        duration: 8000
      });
    }

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;

      Toast.open({
        type: 'is-warning',
        message: 'מתחבר לחשבון שלך מחדש...',
        position: 'is-bottom',
        duration: 8000
      });

      store.dispatch('auth/refresh').then((accessToken) => {
        isAlreadyFetchingAccessToken = false;
        onAccessTokenFetched(accessToken);
      })
        .catch(() => {
          router.go({ path: '/login' });
        });
    }

    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber((accessToken) => {
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        resolve(axios(originalRequest));
      });
    });
    return retryOriginalRequest;
  }
  return Promise.reject(error);
});

// axios.interceptors.response.use((response) => response, (error) => {
//   // const { config } = error;
//   if (error.response) {
//     if (error.response.status === 401 && !error.response.config.ignoreAuthHandling) {
//       store.dispatch('auth/refresh')
//         .then(() => {
//           Toast.open({
//             type: 'is-warning',
//             message: 'מתחבר לחשבון שלך מחדש...',
//             position: 'is-bottom',
//             duration: 4000
//           });
//           // return axios(config);
//           // return axios.request(config);
//         })
//         .catch(() => {
//           router.go({ path: '/login' });
//         });
//     }
//   }
//   return Promise.reject(error);
// });
