import axios from 'axios';

const BASE_URL = process.env.VUE_APP_APIURL;

const get = () => {
    if (get.cache) return Promise.resolve(get.cache);
    return axios.get(`${BASE_URL}/api/LeasingCompanies/Clients`)
    .then((r) => {
        const cache = r.data.map((i) => ({
            Value: i.ClientId,
            Text: i.Name,
            IdNumber: i.IdNumber,
            ForbidTransfer: i.ForbidTransfer
        }));
        get.cache = cache;
        return cache;
    })
    .catch((err) => console.log(err));
};

// old search method without paging
const list = () => axios.get(`${BASE_URL}/api/LeasingCompanies/Clients`)
    .catch((err) => console.log(err));

const search = (model, cancelToken) => axios.post(`${BASE_URL}/api/LeasingCompanies/Clients/Search`, model, {
    cancelToken,
});

const getClient = (clientId) => axios.get(`${BASE_URL}/api/LeasingCompanies/Clients/${clientId}`);
const updateClient = (clientId, model) => axios.put(`${BASE_URL}/api/LeasingCompanies/Clients/${clientId}`, model);
const customersWithReports = () => axios.get(`${BASE_URL}/api/LeasingCompanies/Links/Clients`);
const setTransferStatus = (data) => axios.put(`${BASE_URL}/api/LeasingCompanies/TransferStatus`, data);
const setTransferStatusFromItemIds = (data) => axios.put(`${BASE_URL}/api/LeasingCompanies/TransferStatus/FromItemIds`, data);

const links = (model, cancelToken) => axios.get(`${BASE_URL}/api/LeasingCompanies/Links`, model, {
    cancelToken,
});
const lawyerSign = (data) => axios.put(`${BASE_URL}/api/LeasingCompanies/TransferReports/Sign/ByIds`, data);

const getClientProfile = (clientId) => axios.get(`${BASE_URL}/api/LeasingCompanies/Clients/${clientId}/Profile`);
const printTransfers = (data) => axios.post(`${BASE_URL}/api/LeasingCompanies/TransferReports/Html/ByIds`, data);
const printTransfersByBatchItemId = (id) => axios.get(`${BASE_URL}/api/LeasingCompanies/TransferReports/Html/ByBatchItemId/${id}`);
const downloadTransfer = (url) => axios.get(`${url}`);

const transferReports = (data) => axios.post(`${BASE_URL}/api/LeasingCompanies/TransferReports/ByIds`, data);

const deleteDocument = (clientId, type) => axios.delete(`${BASE_URL}/api/LeasingCompanies/Clients/${clientId}/${type}`);
const putDocument = (clientId, type, data) => axios.put(`${BASE_URL}/api/LeasingCompanies/Clients/${clientId}/${type}`, data);
const getDocument = (clientId, type) => axios.get(`${BASE_URL}/api/LeasingCompanies/Clients/${clientId}/${type}`);

const deleteVehicleDocument = (contractId, type) => axios.delete(`${BASE_URL}/api/LeasingCompanies/VehicleContract/${contractId}/${type}`);
const putVehicleDocument = (contractId, type, data) => axios.put(`${BASE_URL}/api/LeasingCompanies/VehicleContract/${contractId}/${type}`, data);
const getVehicleDocument = (contractId, type) => axios.get(`${BASE_URL}/api/LeasingCompanies/VehicleContract/${contractId}/${type}`);

export default {
    get,
    list,
    links,
    customersWithReports,
    setTransferStatus,
    setTransferStatusFromItemIds,
    printTransfers,
    downloadTransfer,
    search,
    getClientProfile,
    deleteDocument,
    putDocument,
    getDocument,
    getClient,
    updateClient,
    lawyerSign,
    transferReports,
    deleteVehicleDocument,
    putVehicleDocument,
    getVehicleDocument,
    printTransfersByBatchItemId
};
