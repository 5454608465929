import { getField, updateField } from 'vuex-map-fields';
import CoursesService from '@/services/CoursesService';

const getDefaultState = function () {
    return {
        id: null,
        displayName: null,
        unitDisplayNameText: null,
        introText: null,
        sortOrder: null,
        status: null,
        lessonExerciseId: null,
        expoType: null,
        exerciseCategoryId: null,
        ExerciseCategoryName: null,
        groupId: null,
        groupName: null
    };
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = getDefaultState();

const getters = {
    getField,
    form: (state) => ({
        id: state.id,
        displayName: state.displayName,
        unitDisplayNameText: state.unitDisplayNameText,
        introText: state.introText,
        sortOrder: state.sortOrder,
        status: state.status,
        lessonExerciseId: state.lessonExerciseId,
        expoType: state.expoType,
        exerciseCategoryId: state.exerciseCategoryId,
        ExerciseCategoryName: state.ExerciseCategoryName,
        groupId: state.groupId,
        groupName: state.groupName
    }),
};

const mutations = {
    setExpoChapter(state, value) {
        if (value) {
            // value.Transfers.forEach((status) => {
            //     status.Events.sort((a, b) => new Date(a.Date) - new Date(b.Date));
            // });
            Object.assign(state, value);
        } else {
            Object.assign(state, getDefaultState());
        }
    },

    storeReportDetails(state, data) {
        Object.assign(state, data);
    },
    updateField,
};

const actions = {
    async loadExpoChapter({ commit }, data) {
        const course = JSON.parse(localStorage.getItem('course'));
        const res = await CoursesService.getCourseExpoChapters(course.id, {
            EntityId: data.id
        });
        commit('setExpoChapter', res.data.items[0]);
        return res.data;
    },
    async loadExpoChapterAdmin({ commit }, data) {
        const res = await CoursesService.getReportAdmin(data.id);
        commit('setExpoChapter', res.data);
        return res.data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
