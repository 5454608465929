import { getField, updateField } from 'vuex-map-fields';
import TransferItemsService from '@/services/TransferItemsService';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = () => ({
    ErrorMessage: null,
    Id: null,
    IsGood: null,
    PdfUrl: null,
    Report: null,
    Time: null,
    TransferStatusEvents: [],
});

const getters = {
    getField,
    form: (state) => ({
        ErrorMessage: state.ErrorMessage,
        Id: state.Id,
        IsGood: state.IsGood,
        PdfUrl: state.PdfUrl,
        Time: state.Time,
    }),
};

const mutations = {
    setTransfer(state, data) {
        Object.assign(state, data);
    },

    storeTransferDetails(state, data) {
        Object.assign(state, data);
    },
    updateField,
};

const actions = {
    tabs({ commit }, tabs) {
        // set new tabs array
        commit('setTabs', tabs);
    },
    async loadTransfer({ commit }, data) {
        const res = await TransferItemsService.getTransfer(data.id);
        commit('setTransfer', res.data);
        return res.data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
