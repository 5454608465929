import { getField, updateField } from 'vuex-map-fields';
import LessonService from '@/services/LessonService';

const getDefaultState = function () {
    return {
        ahuzon: null,
        exerciseId: null,
        exerciseName: null,
        grayGrade: null,
        grayPercent: null,
        madadTakinEditor: null,
        reportUrl: null,
        starGrade: null,
        starPercent: null,
        startDateTime: null,
        status: null,
        userId: null,
        userName: null,
        grade: null
    };
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = getDefaultState();

const getters = {
    getField,
    form: (state) => ({
        ahuzon: state.ahuzon,
        exerciseId: state.exerciseId,
        exerciseName: state.exerciseName,
        grayGrade: state.grayGrade,
        grayPercent: state.grayPercent,
        madadTakinEditor: state.madadTakinEditor,
        reportUrl: state.reportUrl,
        starGrade: state.starGrade,
        starPercent: state.starPercent,
        startDateTime: state.startDateTime,
        status: state.status,
        userId: state.userId,
        userName: state.userName,
        grade: state.grade
    }),
};

const mutations = {
    setExerciseAnswer(state, value) {
        if (value) {
            // value.Transfers.forEach((status) => {
            //     status.Events.sort((a, b) => new Date(a.Date) - new Date(b.Date));
            // });
            Object.assign(state, value);
        } else {
            Object.assign(state, getDefaultState());
        }
    },

    storeReportDetails(state, data) {
        Object.assign(state, data);
    },
    updateField,
};

const actions = {
    async loadExerciseAnswer({ commit }, data) {
        const course = JSON.parse(localStorage.getItem('course'));
        const res = await LessonService.getExerciseAnswers(course.id, {
            EntityId: data.id,
            PageSize: 1,
            PageNumber: 1
        });
        commit('setExerciseAnswer', res.data.items[0]);
        return res.data;
    },
    async loadExerciseAnswerAdmin({ commit }, data) {
        const res = await LessonService.getReportAdmin(data.id);
        commit('setExerciseAnswer', res.data);
        return res.data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
