import { getField, updateField } from 'vuex-map-fields';
import Vue from 'vue';
import DriverService from '@/services/DriverService';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = () => ({
        City: null,
        DriverNumber: null,
        HasDriversLicense: null,
        HasTransferForm: null,
        HouseNumber: null,
        Id: null,
        IdNumber: null,
        IsActive: null,
        IsManager: null,
        LicenseNumber: null,
        Manager: null,
        ManagerId: null,
        Name: null,
        PhoneNumber: null,
        Email: null,
        Street: null,
        TrackerDriverId: null,
        VehicleContracts: null,
        ZipCode: null,
});

const getters = {
    getField,
    form: (state) => ({
        ManagerId: state.ManagerId,
        TrackerDriverId: state.TrackerDriverId,
        Street: state.Street,
        HouseNumber: state.HouseNumber,
        City: state.City,
        IdNumber: state.IdNumber,
        LicenseNumber: state.LicenseNumber,
        ZipCode: state.ZipCode,
        Name: state.Name,
        PhoneNumber: state.PhoneNumber,
        Email: state.Email,
        DriverNumber: state.DriverNumber,
    }),
};

const mutations = {
    setDriver(state, data) {
        Object.assign(state, data);
    },
    setVehicleContracts(state, data) {
        Vue.set(state, 'VehicleContracts', [...data]);
    },
    storeClientDetails(state, data) {
        Object.assign(state, data);
    },
    updateField,
};

const actions = {
    async loadDriver({ commit }, data) {
        const res = await DriverService.getDriver(data.id);
        commit('setDriver', res.data);
        return res.data;
    },
    // async loadContract({ commit }, data) {
    //     const res = await DriverService.getContract(data.id, );
    //     // commit('setDriver', res.data);
    //     return res.data;
    // },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
