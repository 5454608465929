import Vue from 'vue';
import moment from 'moment';

Vue.filter('datetime', (value) => {
    if (!value) return '';
    const date = moment(value, 'YYYY-MM-DDThh:mm');
    return date.format('DD/MM/YYYY hh:mm');
});
Vue.filter('date', (value) => {
    if (!value) return '';
    const date = moment(value, 'YYYY-MM-DDThh:mm');
    return date.format('DD/MM/YYYY');
});
Vue.filter('currency', (value) => {
    if (!value || typeof value !== 'number') return '-';
    return new Intl.NumberFormat().format(value);
});
