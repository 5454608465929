import Vue from "vue";
import Buefy, { ConfigProgrammatic } from "buefy";
import VueMask from 'v-mask';
// import * as VueGoogleMaps from 'vue2-google-maps';
import * as GmapVue from "gmap-vue";
import VueTheMask from "vue-the-mask";
import Vuelidate from "vuelidate";
import axios from 'axios';
import "chart.js";
import VueFilterDateFormat from "@vuejs-community/vue-filter-date-format";
// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";
import CKEditor from '@ckeditor/ckeditor5-vue2';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import auth from "./auth";
import iframe from "./iframe";
import "buefy/dist/buefy.css";
import "../public/reset.css";
import "../public/rtl.css";
import "../public/animations.css";
import "./filters/dates";
import "./interceptor";

// const Axios = axios.create({
//   // baseURL: 'https://simapi.nevo.co.il/',
//   baseURL: process.env.VUE_APP_APIURL,
//   // baseURL: 'https://hasimulatorapi-preprod.azurewebsites.net/',
//   headers: {
//     'Accept': 'application/ json',
//     'Content-Type': 'application / json'
//   }
// })

Vue.use(auth, { store });
Vue.use(Buefy);
Vue.use(iframe);
Vue.use(CKEditor);
Vue.use(VueMask);
ConfigProgrammatic.setOptions({
  defaultProgrammaticPromise: true
});
Vue.use(VueTheMask);
Vue.use(Vuelidate);
Vue.use(VueFilterDateFormat);

Vue.use(GmapVue, {
  load: {
    key: "AIzaSyAqt5OaqSDQ0CQ1cN9-i4WErrMhanIB1K4",
    libraries: "visualization",
    zoom: 3,
  },
  installComponents: true
});

Vue.config.productionTip = false;
Vue.prototype.$api = axios;

// Sentry.init({
//   Vue,
//   dsn: "https://aa1bb4883f4b46d28093a560708a2d0c@o208346.ingest.sentry.io/6501646",
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["vms.betterway.co.il", /^\//]
//     })
//   ],
//   denyUrls: ["localhost"],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
//   environment: "production",
//   release: "betterway-prod@1.0.0"
// });

new Vue({
  router,
  store,
  // eslint-disable-next-line arrow-parens
  render: h => h(App)
}).$mount("#app");
