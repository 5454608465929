import axios from 'axios';

const BASE_URL = process.env.VUE_APP_APIURL;

const all = (options, cancelToken) => axios.get(`${BASE_URL}/api/Accidents?isAscending=${options.IsAscending}&pageNumber=${options.PageNumber}&pageSize=${options.PageSize}`, {
    cancelToken,
});
const get = (id) => axios.get(`${BASE_URL}/api/Accidents/${id}`);
const update = (id, model) => axios.put(`${BASE_URL}/api/Accidents/${id}`, model);
const requestSignature = (id) => axios.post(`${BASE_URL}/api/Accidents/${id}/pdf`);
// const varifyPhone = (number) => axios.post(`https://api.betterway.co.il//api/account/phonelogin`, { number }); // check real sms msg
const varifyPhone = (number) => axios.post(`${BASE_URL}/api/account/phonelogin`, { number });
// const varifySms = (model) => axios.post(`https://api.betterway.co.il//api/account/phonelogintoken`, model); // check real sms msg
const varifySms = (model) => axios.post(`${BASE_URL}/api/account/phonelogintoken`, model);
const uploadImage = (model) => axios.post(`${BASE_URL}/api/Accidents/UploadImage`, model);
const uploadAccident = (model) => axios.post(`${BASE_URL}/api/Accidents`, model);
const getAccident = (id) => axios.get(`${BASE_URL}/api/Accidents/${id}/pdf`);
const driverSign = (id, data) => axios.get(`https://sign.betterway.co.il/api/template/${id}?mobile=true`, data);

export default {
    all,
    get,
    requestSignature,
    update,
    varifyPhone,
    varifySms,
    uploadImage,
    uploadAccident,
    getAccident,
    driverSign
};
