import { getField, updateField } from 'vuex-map-fields';
import LessonService from '@/services/LessonService';

const getDefaultState = function () {
    return {
        bookAuthor: null,
        bookId: null,
        bookName: null,
        imageUrl: null,
        lessonId: null,
        lessonName: null,
        link: null,
        sortOrder: null,
        status: null
    };
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = getDefaultState();

const getters = {
    getField,
    form: (state) => ({
        bookId: state.bookId,
        bookName: state.bookName,
        bookAuthor: state.bookAuthor,
        imageUrl: state.imageUrl,
        lessonId: state.lessonId,
        lessonName: state.lessonName,
        link: state.link,
        sortOrder: state.sortOrder,
        status: state.status
    }),
};

const mutations = {
    setBook(state, value) {
        if (value) {
            // value.Transfers.forEach((status) => {
            //     status.Events.sort((a, b) => new Date(a.Date) - new Date(b.Date));
            // });
            Object.assign(state, value);
        } else {
            Object.assign(state, getDefaultState());
        }
    },

    storeReportDetails(state, data) {
        Object.assign(state, data);
    },
    updateField,
};

const actions = {
    async loadBook({ commit }, data) {
        console.log(data);
        const course = JSON.parse(localStorage.getItem('course'));
        const res = await LessonService.getAdminBooks(course.id, {
            EntityId: data.id,
            LessonIds: [data.lessonId]
        });
        commit('setBook', res.data.items[0]);
        return res.data;
    },
    async loadBookAdmin({ commit }, data) {
        const res = await LessonService.getReportAdmin(data.id);
        commit('setBook', res.data);
        return res.data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
