import { getField, updateField } from 'vuex-map-fields';
import LessonService from '@/services/LessonService';

const getDefaultState = function () {
    return {
        id: null,
        name: null,
        supportingMaterials: null,
        hasVideos: null,
        hasExercises: null,
        lessonType: null,
        status: null,
        sortOrder: null,
        btnColor: null,
        isHidden: null
    };
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = getDefaultState();

const getters = {
    getField,
    form: (state) => ({
        id: state.id,
        name: state.name,
        supportingMaterials: state.supportingMaterials,
        hasVideos: state.hasVideos,
        hasExercises: state.hasExercises,
        lessonType: state.lessonType,
        status: state.status,
        sortOrder: state.sortOrder,
        btnColor: state.btnColor,
        isHidden: state.isHidden,
    }),
};

const mutations = {
    setLesson(state, value) {
        if (value) {
            // value.Transfers.forEach((status) => {
            //     status.Events.sort((a, b) => new Date(a.Date) - new Date(b.Date));
            // });
            Object.assign(state, value);
        } else {
            Object.assign(state, getDefaultState());
        }
    },

    storeReportDetails(state, data) {
        Object.assign(state, data);
    },
    updateField,
};

const actions = {
    async loadLesson({ commit }, data) {
        const course = JSON.parse(localStorage.getItem('course'));
        const res = await LessonService.getLesson(course.id, data.id);
        commit('setLesson', res.data);
        return res.data;
    },
    async loadLessonAdmin({ commit }, data) {
        const res = await LessonService.getReportAdmin(data.id);
        commit('setLesson', res.data);
        return res.data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
