import { getField, updateField } from 'vuex-map-fields';
import AdminService from '@/services/AdminService';

const getDefaultState = function () {
    return {
        id: null,
        question: null,
        editorNotes: null,
        status: null,
    };
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = getDefaultState();

const getters = {
    getField,
    form: (state) => ({
        id: state.id,
        question: state.question,
        editorNotes: state.editorNotes,
        status: state.status,
    }),
};

const mutations = {
    setQuestion(state, value) {
        if (value) {
            // value.Transfers.forEach((status) => {
            //     status.Events.sort((a, b) => new Date(a.Date) - new Date(b.Date));
            // });
            Object.assign(state, value);
        } else {
            Object.assign(state, getDefaultState());
        }
    },

    storeReportDetails(state, data) {
        Object.assign(state, data);
    },
    updateField,
};

const actions = {
    async loadQuestion({ commit }, data) {
        const res = await AdminService.getQuestionsInBank({ PageSize: 999, IsAscending: false, PageNumber: 1, ResultType: 3, EntityId: data.id });
        commit('setQuestion', res.data.items[0]);
        return res.data;
    },
    async loadQuestionAdmin({ commit }, data) {
        const res = await AdminService.getReportAdmin(data.id);
        commit('setQuestion', res.data);
        return res.data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
