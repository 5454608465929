var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal is-active",
      class: [_vm.customClass],
      attrs: { id: "VueIframe__Component", tabindex: "-1" }
    },
    [
      _c("div", {
        staticClass: "modal-background",
        on: {
          click: function($event) {
            return _vm.cancel("outside")
          }
        }
      }),
      _c("div", { staticClass: "animation-content" }, [
        _c("iframe", {
          attrs: {
            id: "watch-document",
            name: "watch-document",
            src: "/form.builder/index.html?" + _vm.url
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }