import axios from 'axios';

const BASE_URL = process.env.VUE_APP_APIURL;

const getUser = (userId) => axios.get(`${BASE_URL}/api/admin/users/search/user/${userId}`);

const updateUser = (data) => axios.put(`${BASE_URL}/api/admin/users`, data);

const addUser = (data) => axios.post(`${BASE_URL}/api/Admin/Users`, data);

const getUserExercises = (userId, courseId) => axios.get(`${BASE_URL}/api/admin/users/Search/User/${userId}/exercises?courseId=${courseId}`);

const updateUserExercise = (userId, exerciseId, courseId) => axios.post(`${BASE_URL}/api/admin/users/Search/User/${userId}/exercises/${exerciseId}?courseId=${courseId}`);

const getUserLectures = (userId) => axios.get(`${BASE_URL}/api/admin/users/Search/User/${userId}/lectures`);

const getUserGroups = (userId) => axios.get(`${BASE_URL}/api/admin/users/search/user/${userId}/groups`);

const getUserTracks = (userId) => axios.get(`${BASE_URL}/api/admin/users/search/user/${userId}/tracks`);

const updateUserTracks = (userId, data) => axios.put(`${BASE_URL}/api/Admin/Users/${userId}/tracks`, data);

const updateUserCourses = (userId, data) => axios.put(`${BASE_URL}/api/Admin/Users/${userId}/studentcourses`, data);

const getUserCourses = (userId) => axios.get(`${BASE_URL}/api/admin/users/search/user/${userId}/studentCourses`);

const getUserRemarks = (courseId, userId) => axios.get(`${BASE_URL}/api/admin/users/${userId}/course/${courseId}/remarks`);

const addRemark = (data) => axios.post(`${BASE_URL}/api/admin/users/remarks`, data);

export default {
    getUser,
    updateUser,
    addUser,
    getUserExercises,
    updateUserExercise,
    getUserLectures,
    getUserGroups,
    getUserTracks,
    updateUserTracks,
    updateUserCourses,
    getUserCourses,
    getUserRemarks,
    addRemark
};
