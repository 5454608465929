import { getField, updateField } from 'vuex-map-fields';
import AdminService from '@/services/AdminService';

const getDefaultState = function () {
    return {
        id: null,
        name: null,
        category: null,
        facts: null,
        halachot: null,
        miniRatio: null,
        modelSubLabels: null,
        nevoVerdictId: null,
        nevoVerdictUrl: null,
        resolution: null,
        subCategory: null,
        summary: null,
        verdict: null
    };
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = getDefaultState();

const getters = {
    getField,
    form: (state) => ({
        id: state.id,
        name: state.name,
        category: state.category,
        facts: state.facts,
        halachot: state.halachot,
        miniRatio: state.miniRatio,
        modelSubLabels: state.modelSubLabels,
        nevoVerdictId: state.nevoVerdictId,
        nevoVerdictUrl: state.nevoVerdictUrl,
        resolution: state.resolution,
        subCategory: state.subCategory,
        summary: state.summary,
        verdict: state.verdict
    }),
};

const mutations = {
    setVerdictSummary(state, value) {
        if (value) {
            // value.Transfers.forEach((status) => {
            //     status.Events.sort((a, b) => new Date(a.Date) - new Date(b.Date));
            // });
            Object.assign(state, value);
        } else {
            Object.assign(state, getDefaultState());
        }
    },

    storeReportDetails(state, data) {
        Object.assign(state, data);
    },
    updateField,
};

const actions = {
    async loadVerdictSummary({ commit }, data) {
        const res = await AdminService.getVerdictSummaries(data.id);
        commit('setVerdictSummary', res.data);
        return res.data;
    },
    async loadVerdictSummaryAdmin({ commit }, data) {
        const res = await AdminService.getReportAdmin(data.id);
        commit('setVerdictSummary', res.data);
        return res.data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
