/* eslint-disable arrow-parens */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import ReportService from "@/services/ReportService";
import StatisticsService from "@/services/StatisticsService";
import moment from "moment";

const state = () => ({
  mapData: null,
  lineChartData: null,
  pieData: null
});

const getters = {
  mapData: state => state.mapData,
  lineChartData: state => state.lineChartData,
  getPieData: state => state.pieData
};

const mutations = {
  setMapData(state, value) {
    state.mapData = value;
  },
  setMapDataToStorage(state, value) {
    if (value) {
      const valWithExperation = {
        data: value,
        expired: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
      };
      const profileId = localStorage.getItem("profile_id");
      localStorage.setItem(`dashboardMapData-${profileId}`, JSON.stringify(valWithExperation));
    } else {
      localStorage.removeItem("dashboardMapData");
    }
  },
  setLineChartData(state, value) {
    state.lineChartData = value;
  },
  setLineChartDataToStorage(state, value) {
    if (value) {
      const valWithExperation = {
        data: value,
        expired: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
      };
      const profileId = localStorage.getItem("profile_id");
      localStorage.setItem(
        `dashboardLineChartData-${profileId}`,
        JSON.stringify(valWithExperation)
      );
    } else {
      localStorage.removeItem("dashboardLineChartData");
    }
  },

  setPieData(state, value) {
    state.pieData = value;
  },
  setPieDataToStorage(state, value) {
    if (value) {
      const valWithExperation = {
        data: value,
        expired: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
      };
      const profileId = localStorage.getItem("profile_id");
      localStorage.setItem(`dashboardPieData-${profileId}`, JSON.stringify(valWithExperation));
    } else {
      localStorage.removeItem("dashboardPieData");
    }
  },
  clearCache(state) {
    const localStorageKeys = Object.keys(localStorage);
    const storageArr = ["dashboardMapData", "dashboardLineChartData", "dashboardPieData"];
    const filteredArr = [];

    localStorageKeys.forEach(k => {
      storageArr.forEach(str => {
        if (k.includes(str)) filteredArr.push(k);
      });
    });

    state.mapData = null;
    state.lineChartData = null;
    state.pieData = null;

    filteredArr.forEach(str => {
      localStorage.removeItem(str);
    });
  }
};

const actions = {
  loadStorage({ commit }) {
    const profileId = localStorage.getItem(`profile_id`);

    const mapData = localStorage.getItem(`dashboardMapData-${profileId}`);
    if (mapData) {
      const parsedMapData = JSON.parse(mapData);
      if (new Date(parsedMapData.expired) >= new Date(Date.now())) {
        commit("setMapData", parsedMapData.data);
      }
    }

    const lineChartData = localStorage.getItem(`dashboardLineChartData-${profileId}`);
    if (lineChartData) {
      const parsedLineChartData = JSON.parse(lineChartData);
      if (new Date(parsedLineChartData.expired) >= new Date(Date.now())) {
        commit("setLineChartData", parsedLineChartData.data);
      }
    }
    const pieData = localStorage.getItem(`dashboardPieData-${profileId}`);
    if (pieData) {
      const parsedPieData = JSON.parse(pieData);
      if (new Date(parsedPieData.expired) >= new Date(Date.now())) {
        commit("setPieData", parsedPieData.data);
      }
    }
  },
  getMapData({ commit }) {
    return ReportService.forMap({
      PageSize: 999999,
      FromDateTaken: moment()
        .subtract(1, "year")
        .toDate()
    }).then(res => {
      commit("setMapData", res);
      commit("setMapDataToStorage", res);
      return res;
    });
  },
  getLineChartData({ commit }) {
    return StatisticsService.byMonths().then(res => {
      commit("setLineChartData", res.data);
      commit("setLineChartDataToStorage", res.data);
      return res.data;
    });
  },
  getPieData({ commit }) {
    return StatisticsService.byStatus(new Date().getFullYear()).then(res => {
      commit("setPieData", res.data);
      commit("setPieDataToStorage", res.data);
      return res.data;
    });
  },
  clearCache({ commit }) {
    commit("clearCache");
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
