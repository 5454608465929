<template>
  <div
    id="VueIframe__Component"
    class="modal is-active"
    :class="[customClass]"
    tabindex="-1"
  >
    <div class="modal-background" @click="cancel('outside')" />
    <div class="animation-content">
      <iframe
        id="watch-document"
        name="watch-document"
        :src="`/form.builder/index.html?${url}`"
      />
    </div>
  </div>
</template>

<script>
function removeElement(el) {
  if (typeof el.remove !== "undefined") {
    el.remove();
  } else if (typeof el.parentNode !== "undefined" && el.parentNode !== null) {
    el.parentNode.removeChild(el);
  }
}

export default {
  name: "VueIframe",
  props: {
    html: String,
    type: String,
    clientId: Number,
    isAdmin: Boolean,
    active: Boolean,
    component: [Object, Function, String],
    onCancel: {
      type: Function,
      default: () => {},
    },
    onSave: {
      type: Function,
      default: () => {},
    },
    customClass: String,
  },
  computed: {
    url() {
      return `type=${this.type}&clientId=${this.clientId}`;
    },
  },
  data() {
    return {
      isActive: this.active || false,
    };
  },
  watch: {
    active(value) {
      this.isActive = value;
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
      this.onCancel.apply(null);
      this.close();
    },
    close() {
      this.$emit("close");
      this.isActive = false;
      setTimeout(() => {
        this.$destroy();
        removeElement(this.$el);
      }, 150);
    },
    vanishButtons(self, iFrameHtml) {
      window.frames["watch-document"].load(self.html);
      Array.from(iFrameHtml.document.querySelectorAll("button.remove")).filter(
        (el) =>
          (el.innerText = "מחיקת דף" ? (el.style.visibility = "hidden") : "")
      );
    },
    visibleButtons(self, iFrameHtml) {
      Array.from(
        iFrameHtml.document.getElementsByClassName("admin-only")
      ).forEach((b) => {
        b.style.visibility = "visible";
      });
      window.frames["watch-document"].load(self.html);
    },
    message(e) {
      if (e.origin === window.location.origin) {
        if (e.data) {
          try {
            const data = JSON.parse(unescape(e.data));
            this.onSave({ data, close: this.close });
          } catch (err) {
            console.log(err);
          }
        }
      }
    },
  },
  mounted() {
    const self = this;
    const interval = setInterval(() => {
      const iFrameHtml = window.frames["watch-document"];
      if (iFrameHtml) {
        if (!this.isAdmin && this.type === "GeneralContractForm") {
          this.vanishButtons(self, iFrameHtml);
        } else {
          this.visibleButtons(self, iFrameHtml);
        }
        clearInterval(interval);
      }
    }, 200);
    window.addEventListener("message", this.message, false);
  },

  beforeMount() {
    document.body.appendChild(this.$el);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.message);
  },
};
</script>
<style scoped lang="scss">
#VueIframe__Component {
  position: fixed;
  z-index: 100;
  padding: 10px;
}
.animation-content {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1;
  width: 1100px;

  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
