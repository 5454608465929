import axios from 'axios';

const BASE_URL = process.env.VUE_APP_APIURL;

const getTransfer = (entityId) => axios.get(`${BASE_URL}/api/ClientTransferItems/${entityId}`);
const getDriverTransfer = (entityId) => axios.get(`${BASE_URL}/api/DriverTransferItems/${entityId}`);

export default {
    getTransfer,
    getDriverTransfer,
};
