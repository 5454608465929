import { getField, updateField } from 'vuex-map-fields';
import VehicleService from '../../services/VehicleService';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = () => ({
    EndDate: null,
    HasDriversLicenseHtml: null,
    HasRentalPickUpFormHtml: null,
    HasRentalReturnFormHtml: null,
    HasTransferHtml: null,
    Id: null,
    Profile: null,
    ProfileId: null,
    StartDate: null,
    UpdateDate: null,
    VehicleId: null,
});

const getters = {
    getField,
    form: (state) => ({
        StartDate: state.StartDate,
        EndDate: state.EndDate,
    }),
};

const mutations = {
    setContract(state, data) {
        Object.assign(state, data);
    },
    updateField,
};

const actions = {
    async loadContract({ commit }, data) {
        const res = await VehicleService.getContract(data.id);
        commit('setContract', res.data);
        return res.data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
