import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/admin',
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/Admin.vue'),
    redirect: '/admin/profiles',
    children: [
      {
        path: '/admin/profiles',
        name: 'AdminProfiles',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminProfiles.vue'),
      },
      {
        path: '/admin/users',
        name: 'AdminUsers',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminUsers.vue'),
      },
      {
        path: '/admin/questionsbank',
        name: 'AdminQuestionsBank',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminQuestionsBank.vue'),
      },
      {
        path: '/admin/videosbank',
        name: 'AdminVideosBank',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminVideosBank.vue'),
      },
      {
        path: '/admin/booksbank',
        name: 'AdminBooksBank',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminBooksBank.vue'),
      },
      {
        path: '/admin/quotes',
        name: 'AdminQuotes',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminQuotes.vue'),
      },
      {
        path: '/admin/coupons',
        name: 'AdminCoupons',
        component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/Coupons.vue'),
      },
      {
        path: '/admin/tasks',
        name: 'AdminTasks',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminTasks.vue'),
      },
      {
        path: '/admin/search/reports',
        name: 'AdminSearchReports',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminSearchReports.vue'),
      },
      {
        path: '/admin/search/unmanagedreports',
        name: 'AdminSearchUnmanagedReports',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminSearchUnmanagedReports.vue'),
      },
      {
        path: '/admin/search/vehicles',
        name: 'AdminSearchVehicles',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminVehicles.vue'),
      },
      {
        path: '/admin/search/municipalities',
        name: 'AdminSearchMunicipalities',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminMunicipalities.vue'),
      },
      {
        path: '/admin/import/reports',
        name: 'AdminImportReports',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminImportReports.vue'),
      },
      {
        path: '/admin/import/drivers',
        name: 'AdminImportDrivers',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminImportDrivers.vue'),
      },
      {
        path: '/admin/import/vehicles',
        name: 'AdminImportVehicles',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminImportVehicles.vue'),
      },
      {
        path: '/admin/import/vehicles/owned',
        name: 'AdminImportVehicles',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminImportOwnedVehicles.vue'),
      },
      {
        path: '/admin/import/newvehicles',
        name: 'AdminImportNewVehicles',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminImportNewVehicles.vue'),
      },
      {
        path: '/admin/import/police',
        name: 'AdminImportPolice',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminImportPolice.vue'),
      },
      {
        path: '/admin/import/driversContract',
        name: 'AdminImportPolice',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminImportDriversContract.vue'),
      },
      {
        path: '/admin/import/create',
        name: 'AdminImportCreate',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminImportCreate.vue'),
      },
      {
        path: '/admin/action/updateexerciselaws',
        name: 'AdminUpdateExerciseLaws',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminUpdateExerciseLaws.vue'),
      },
      {
        path: '/admin/action/updateVideoContent',
        name: 'AdminUpdateVideoContent',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminUpdateVideoContent.vue'),
      },
      {
        path: '/admin/action/updatelawcollections',
        name: 'AdminUpdateLawCollections',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminUpdateLawCollections.vue'),
      },
      {
        path: '/admin/action/unitReplication',
        name: 'AdminUnitReplication',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminUnitReplication.vue'),
      },
      {
        path: '/admin/action/verdictReplication',
        name: 'AdminVerdictReplication',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminVerdictReplication.vue'),
      },
      {
        path: '/admin/action/createAnswersFiles',
        name: 'AdminCreateAnswersFiles',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminCreateAnswersFiles.vue'),
      },
      {
        path: '/admin/action/verdictscollections',
        name: 'AdminVerdictsCollections',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminVerdictsCollections.vue'),
      },
      {
        path: '/admin/action/manageLawsCollections',
        name: 'AdminManageLawsCollections',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminManageLawsCollections.vue'),
      },
      {
        path: '/admin/import/locationdatascan',
        name: 'AdminLocationDataScan',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminLocationDataScan.vue'),
      },
      {
        path: '/admin/import/generatealerts',
        name: 'AdminAlertGenerator',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminAlertGenerator.vue'),
      },
      {
        path: '/admin/analytics/yearly',
        name: 'AdminAnalyticsYearly',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminAnalyticsYearly.vue'),
      },
      {
        path: '/admin/crawlReports',
        name: 'AdminCrawlReports',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminCrawlReports.vue'),
      },
      {
        path: '/addreport',
        name: 'addReport',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/addReport.vue'),
      },
    ],
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
  },
  {
    path: '/users',
    name: 'CourseUsers',
    component: () => import(/* webpackChunkName: "CourseUsers" */ '@/views/CourseUsers.vue'),
  },
  {
    path: '/requests',
    name: 'CourseRequests',
    component: () => import(/* webpackChunkName: "CourseRequests" */ '@/views/CourseRequests.vue'),
  },
  {
    path: '/contracts/:id',
    name: 'VehicleContract',
    component: () => import(/* webpackChunkName: "vehicleContractEntity" */ '@/views/VehicleContractEntity.vue'),
  },
  {
    path: '/driverContract/:id',
    name: 'DriverContract',
    component: () => import(/* webpackChunkName: "driverContractEntity" */ '@/views/DriverContractEntity.vue'),
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: () => import(/* webpackChunkName: "tasks" */ '@/views/Tasks.vue'),
  },
  {
    path: '/accidents',
    name: 'Accidents',
    component: () => import(/* webpackChunkName: "accidents" */ '@/views/Accidents.vue'),
  },
  {
    path: '/accidents/:id',
    name: 'Accident',
    component: () => import(/* webpackChunkName: "accidents" */ '@/views/AccidentEntity.vue'),
  },
  {
    path: '/exercise/:id',
    name: 'Exercise',
    component: () => import(/* webpackChunkName: "ExerciseEntity" */ '@/views/ExerciseEntity.vue'),
  },
  {
    path: '/question/:id',
    name: 'Question',
    component: () => import(/* webpackChunkName: "QuestionEntity" */ '@/views/QuestionEntity.vue'),
  },
  {
    path: '/lawCollection/:id',
    name: 'LawCollection',
    component: () => import(/* webpackChunkName: "QuestionEntity" */ '@/views/LawCollectionEntity.vue'),
  },
  {
    path: '/newQuestion',
    name: 'NewQuestion',
    component: () => import(/* webpackChunkName: "QuestionEntity" */ '@/views/NewQuestion.vue'),
  },
  {
    path: '/lessons/:id',
    name: 'Lesson',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/LessonEntity.vue'),
  },
  {
    path: '/videos',
    name: 'Videos',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/Videos.vue'),
  },
  {
    path: '/books',
    name: 'Books',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/Books.vue'),
  },
  {
    path: '/exercises',
    name: 'Exercises',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/Exercises.vue'),
  },
  {
    path: '/laws',
    name: 'Laws',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/Laws.vue'),
  },
  {
    path: '/verdicts',
    name: 'Verdicts',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/Verdicts.vue'),
  },
  {
    path: '/groups',
    name: 'Groups',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/Groups.vue'),
  },
  {
    path: '/group/:id',
    name: 'GroupEntityPage',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/GroupEntity.vue'),
  },
  {
    path: '/exerciseAnswers',
    name: 'ExerciseAnswers',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/ExerciseAnswers.vue'),
  },
  {
    path: '/lecturesLearningTracks',
    name: 'LecturesLearningTracks',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/LecturesLearningTracks.vue'),
  },
  {
    path: '/lecturesRegions',
    name: 'LecturesRegions',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/LecturesRegions.vue'),
  },
  {
    path: '/lecturesHalls',
    name: 'LecturesHalls',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/LecturesHalls.vue'),
  },
  {
    path: '/lecturesTeachers',
    name: 'LecturesTeachers',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/LecturesTeachers.vue'),
  },
  {
    path: '/lecturesFeedbacksStatements',
    name: 'LecturesFeedbacksStatements',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/LecturesFeedbacksStatements.vue'),
  },
  {
    path: '/lectures/:id?',
    name: 'Lectures',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/Lectures.vue'),
  },
  {
    path: '/lecturesLearningTrack/:id/Subjects',
    name: 'LectureTrackSubjects',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/LectureTrackSubjects.vue'),
  },
  {
    path: '/course/:courseId/exercisePrint/:exerciseId',
    name: 'ExercisePrint',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/ExercisePrint.vue'),
  },
  {
    path: '/expoChapters',
    name: 'ExpoChapters',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/ExpoChapters.vue'),
  },
  {
    path: '/expoChapter/:id',
    name: 'ExpoChapterPage',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/ExpoChapterEntity.vue'),
  },
  {
    path: '/expoChapter/:id/questions',
    name: 'ExpoChapterQuestions',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/ExpoChapterQuestions.vue'),
  },
  {
    path: '/admin/reports/:id',
    name: 'AdminReport',
    component: () => import(/* webpackChunkName: "adminReportEntity" */ '@/views/AdminReportEntity.vue'),
  },
  {
    path: '/user/:id',
    name: 'User',
    component: () => import(/* webpackChunkName: "UserEntity" */ '@/views/UserEntity.vue'),
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "reports" */ '@/views/Reports.vue'),
  },
  {
    path: '/lessons',
    name: 'Lessons',
    component: () => import(/* webpackChunkName: "Lessons" */ '@/views/Lessons.vue'),
  },
  {
    path: '/transfers',
    name: 'Transfers',
    redirect: '/transfers/company',
    component: {
      // Inline declaration of a component that renders our <router-view>
      render: (c) => c('router-view'),
    },
    children: [
      {
        path: '/transfers/company',
        name: 'TransfersCompany',
        component: () => import(/* webpackChunkName: "transfers" */ '@/views/TransfersCompanies.vue'),
      },
      {
        path: '/transfers/driver',
        name: 'TransfersDrivers',
        component: () => import(/* webpackChunkName: "transfers" */ '@/views/TransfersDrivers.vue'),
      },
    ],
  },
  {
    path: '/transfers/:id',
    name: 'Transfer',
    component: () => import(/* webpackChunkName: "transfers" */ '@/views/TransferCompanyEntity.vue'),
  },
  {
    path: '/transfersDriver/:id',
    name: 'Transfer',
    component: () => import(/* webpackChunkName: "transfers" */ '@/views/TransferDriverEntity.vue'),
  },
  {
    path: '/tollroads',
    name: 'TollRoads',
    redirect: '/tollroads/drives',
    component: {
      // Inline declaration of a component that renders our <router-view>
      render: (c) => c('router-view'),
    },
    children: [
      {
        path: '/tollroads/drives',
        name: 'TollRoadsDrives',
        component: () => import(/* webpackChunkName: "transfers" */ '@/views/TollRoadsDrives.vue'),
      },
      {
        path: '/tollroads/import',
        name: 'TollRoadsImportation',
        component: () => import(/* webpackChunkName: "transfers" */ '@/views/TollRoadsImportation.vue'),
      },
      // {
      //   path: '/tollroads/log',
      //   name: 'TollRoadsImportation',
      //   component: () => import(/* webpackChunkName: "transfers" */ '@/views/TollRoadsImportation.vue'),
      // },
    ],
  },
  {
    path: '/vehicles',
    name: 'Vehicles',
    component: () => import(/* webpackChunkName: "vehicles" */ '@/views/Vehicles.vue'),
  },
  {
    path: '/vehicles/:id',
    name: 'Vehicle',
    component: () => import(/* webpackChunkName: "vehicles" */ '@/views/VehicleEntity.vue'),
  },
  {
    path: '/admin/vehicles/:id',
    name: 'AdminVehicle',
    component: () => import(/* webpackChunkName: "adminVehicles" */ '@/views/AdminVehicleEntity.vue'),
  },
  {
    path: '/course',
    name: 'Course',
    redirect: '/course/general',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Course.vue'),
    children: [
      {
        path: 'general',
        name: 'CourseGeneral',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/CourseGeneral.vue'),
      },
      {
        path: 'messages',
        name: 'CourseMessages',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/CourseMessages.vue'),
      },
      {
        path: 'settings',
        name: 'ProfileSettings',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/ProfileSettings.vue'),
      },
      {
        path: 'navigation',
        name: 'CourseNavigation',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/CourseNavigation.vue'),
      },
      {
        path: 'calendar',
        name: 'CourseCalendar',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/CourseCalendar.vue'),
      },
      {
        path: 'admincourserequests',
        name: 'AdminCourseRequests',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/AdminCourseRequests.vue'),
      },
    ],
  },
  {
    path: '/clients',
    name: 'Clients',
    component: () => import(/* webpackChunkName: "clients" */ '@/views/Clients.vue'),
  },
  {
    path: '/clients/:id',
    name: 'Client',
    component: () => import(/* webpackChunkName: "clients" */ '@/views/ClientEntity.vue'),
  },
  {
    path: '/drivers',
    name: 'Drivers',
    component: () => import(/* webpackChunkName: "drivers" */ '@/views/Drivers.vue'),
  },
  {
    path: '/drivers/:id',
    name: 'Driver',
    component: () => import(/* webpackChunkName: "drivers" */ '@/views/DriverEntity.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    meta: {
      layout: 'layout-full',
    },
  },
  {
    path: '/reset',
    name: 'ResetPassword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '@/views/ResetPassword.vue'),
    meta: {
      layout: 'layout-full',
      ignoreLogin: true,
    },
  },
  {
    path: '/driverAccident/:plateNumber',
    name: 'driverAccident',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "driverAccident" */ '@/components/AccidentEntityDetails.vue'),
    component: () => import(/* webpackChunkName: "driverAccident" */ '@/views/DriverAccident.vue'),
    meta: {
      layout: 'layout-full',
      ignoreLogin: true,
    },
  },
  {
    path: '/forgot',
    name: 'Forgot',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '@/views/Forgot.vue'),
    meta: {
      layout: 'layout-full',
      ignoreLogin: true,
    },
  },
  { path: '*', redirect: '/' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const adminRouteNames = [
  "Login", "AdminProfiles", "AdminCoupons", "AdminQuotes", "AdminBooksBank", "AdminVideosBank", "AdminQuestionsBank", "AdminUsers", "AdminUpdateExerciseLaws",
  "AdminUpdateVideoContent", "AdminUpdateLawCollections", "AdminUnitReplication", "AdminVerdictReplication", "AdminCreateAnswersFiles", "AdminVerdictsCollections",
  "AdminManageLawsCollections", "Question", "NewQuestion", "LawCollection"
];

router.beforeEach((to, from, next) => {
  if (to.meta.ignoreLogin) {
    return next();
  }
  if (!adminRouteNames.includes(to.name)) {
    if (!store.state.auth.course?.id) {
      router.replace('admin');
    }
  }
  if (to.name === 'Login') { // check if "to"-route is "callback" and allow access
    if (!router.app.$auth.isAuthenticated()) {
      next();
    } else {
      next(false);
    }
  } else if (router.app.$auth.isAuthenticated()) { // if authenticated allow access
    next();
  } else { // trigger auth login
    router.app.$auth.login()
      .then(() => {
        next();
      })
      .catch(() => {
        router.push({ name: 'Login' });
      });
  }
  return false;
});

export default router;
