import { getField, updateField } from 'vuex-map-fields';
import ReportService from '@/services/ReportService';

const getDefaultState = function () {
    return {
        Address: null,
        AddressLat: null,
        AddressLon: null,
        Alerts: null,
        ClientComment: null,
        CreateDate: null,
        CurrentAmount: null,
        DateTaken: null,
        DeliveryEvents: null,
        Driver: null,
        DriverAmount: null,
        DriverId: null,
        DriverTransferStatus: null,
        DriverTransferStatusEvents: null,
        HasFailedVerification: null,
        HasScreenshot: null,
        Id: null,
        ImageUrl: null,
        IsPaid: null,
        IsPaidByDriver: null,
        IsPayable: null,
        LastPayDate: null,
        ManagerComment: null,
        MuniIdNumber: null,
        MuniReferenceNumber: null,
        Municipality: null,
        OriginalAmount: null,
        OverpaidAmount: null,
        Owner: null,
        PartialPaidAmount: null,
        PaymentDate: null,
        PaymentReferenceNumber: null,
        PaymentUrl: null,
        ProfileName: null,
        Reason: null,
        RelevantLaw: null,
        ReportCause: null,
        ReportNumber: null,
        Status: null,
        StatusEvents: null,
        TransferStatus: null,
        TransferStatusEvents: null,
        Transfers: null,
        Vehicle: null,
        VerificationDate: null,
    };
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = getDefaultState();

const getters = {
    getField,
    form: (state) => ({
        VerificationDate: state.VerificationDate,
        MuniIdNumber: state.MuniIdNumber,
        LastPayDate: state.LastPayDate,
        OriginalAmount: state.OriginalAmount,
        CurrentAmount: state.CurrentAmount,
        ProfileName: state.ProfileName,
        Reason: state.Reason,
        RelevantLaw: state.RelevantLaw,
        Address: state.Address,
        Owner: state.Owner?.Name,
        CreateDate: state.CreateDate,
        Status: state.Status?.Name,
        PartialPaidAmount: state.PartialPaidAmount,
        MuniReferenceNumber: state.MuniReferenceNumber,
        DriverAmount: state.DriverAmount,
    }),
    reportWidgetModal: (state) => ({
        Id: state.Id,
        ReportNumber: state.ReportNumber,
        LastPayDate: state.LastPayDate,
        IsPaid: state.IsPaid,
        Status: state.Status?.Name,
        Municipality: state.Municipality,
        Address: state.Address,
        CurrentAmount: state.CurrentAmount,
        Reason: state.Reason,
        RelevantLaw: state.RelevantLaw,
        Owner: state.Owner?.Name,
        CreateDate: state.CreateDate,
        PartialPaidAmount: state.PartialPaidAmount,
        MuniReferenceNumber: state.MuniReferenceNumber,
        DriverAmount: state.DriverAmount,
    }),
    reportPaymentModel: (state) => ({
        IsPaid: state.IsPaid,
        IsPaidByDriver: state.IsPaidByDriver,
        OverpaidAmount: state.OverpaidAmount,
        PartialPaidAmount: state.PartialPaidAmount,
        PaymentDate: state.PaymentDate,
        PaymentReferenceNumber: state.PaymentReferenceNumber,
        PaymentUrl: state.PaymentUrl,
    }),
    editPaymentModel: (state) => ({
        IsPaid: state.IsPaid,
        PaymentDate: state.PaymentDate,
        PaymentReferenceNumber: state.PaymentReferenceNumber,
    }),
};

const mutations = {
    setReport(state, value) {
        if (value) {
            // value.Transfers.forEach((status) => {
            //     status.Events.sort((a, b) => new Date(a.Date) - new Date(b.Date));
            // });
            Object.assign(state, value);
        } else {
            Object.assign(state, getDefaultState());
        }
    },

    storeReportDetails(state, data) {
        Object.assign(state, data);
    },
    updateField,
};

const actions = {
    async loadReport({ commit }, data) {
        const res = await ReportService.getReport(data.id);
        commit('setReport', res.data);
        return res.data;
    },
    async loadReportAdmin({ commit }, data) {
        const res = await ReportService.getReportAdmin(data.id);
        commit('setReport', res.data);
        return res.data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
