import axios from 'axios';

const BASE_URL = process.env.VUE_APP_APIURL;

const byMonths = (cancelToken) => axios.get(`${BASE_URL}/api/Statistics/Reports/ByMonths`, {
    cancelToken,
});
const lastYearsAmount = (cancelToken) => axios.get(`${BASE_URL}/api/Statistics/LastYearsAmount`, {
    cancelToken,
});
const managedVehicleCount = (cancelToken) => axios.get(`${BASE_URL}/api/Statistics/ManagedVehicleCount`, {
    cancelToken,
});
const unmanagedVehicleCount = (cancelToken) => axios.get(`${BASE_URL}/api/Statistics/UnmanagedVehicleCount`, {
    cancelToken,
});
const averagePerVehicle = (cancelToken) => axios.get(`${BASE_URL}/api/Statistics/AveragePerVehicle`, {
    cancelToken,
});
const unpaidAmount = (cancelToken) => axios.get(`${BASE_URL}/api/Statistics/UnpaidAmount`, {
    cancelToken,
});
const transfersCompleted = () => axios.get(`${BASE_URL}/api/Statistics/TransfersCompleted`);
const awaitingTransfers = () => axios.get(`${BASE_URL}/api/Statistics/AwaitingTransfers`);
const didntTransfer = () => axios.get(`${BASE_URL}/api/Statistics/DidntTransfer`);
const pie = (year, cancelToken) => axios.get(`${BASE_URL}/api/Statistics/Transfers/Pie?year=${year}`, {
    cancelToken,
});
const byStatus = (year, cancelToken) => axios.get(`${BASE_URL}/api/Statistics/Reports/ByStatus?year=${year}`, {
    cancelToken,
});
const unpaidReportCount = (cancelToken) => axios.get(`${BASE_URL}/api/Statistics/UnpaidReportCount`, {
    cancelToken,
});

export default {
    byMonths,
    lastYearsAmount,
    managedVehicleCount,
    unmanagedVehicleCount,
    averagePerVehicle,
    unpaidAmount,
    transfersCompleted,
    awaitingTransfers,
    didntTransfer,
    pie,
    byStatus,
    unpaidReportCount,
};
