import { getField, updateField } from "vuex-map-fields";
import DriverService from "../../services/DriverService";

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = () => ({
  HasTransferHtml: null,
  Id: null,
  Driver: null,
  Vehicle: null,
  StartDate: null,
  CreateDate: null,
  EndDate: null,
  UpdateDate: null,
  DriverId: null,
  VehicleId: null
});

const getters = {
  getField,
  // eslint-disable-next-line arrow-parens
  form: state => ({
    StartDate: state.StartDate,
    EndDate: state.EndDate
  })
};

const mutations = {
  setContract(state, data) {
    Object.assign(state, data);
  },
  updateField
};

const actions = {
  async loadContract({ commit }, data) {
    const res = await DriverService.getContract(data.id);
    commit("setContract", res.data);
    return res.data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
