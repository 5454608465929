/* eslint  no-param-reassign: "off" */
import Vue from 'vue';
import VueIframe from './components/VueIframe.vue';

const ModalProgrammatic = {
    open(params) {
        let parent;

        if (params.parent) {
            parent = params.parent;
            delete params.parent;
        }

        const vm = Vue;
        const ModalComponent = vm.extend(params.component ?? VueIframe);
        const component = new ModalComponent({
            parent,
            el: document.createElement('div'),
            propsData: params,
        });

        return component;
    },
};

const Plugin = {
    install() {
        Vue.prototype.$iframe = ModalProgrammatic;
    },
};

export default Plugin;

export {
    ModalProgrammatic,
};
