import { getField, updateField } from 'vuex-map-fields';
import UserService from '@/services/UserService';

const getDefaultState = function () {
    return {
        id: null,
        privateName: null,
        lastName: null,
        userName: null,
        phone1: null,
        address: null,
        createdate: null,
        status: null,
        userTypeId: null,
        isAdminLinkAllowed: null
    };
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = getDefaultState();

const getters = {
    getField,
    form: (state) => ({
        id: state.id,
        privateName: state.privateName,
        lastName: state.lastName,
        userName: state.userName,
        phone1: state.phone1,
        address: state.address,
        createdate: state.createdate,
        status: state.status,
        userTypeId: state.userTypeId,
        isAdminLinkAllowed: state.isAdminLinkAllowed,
    }),
};

const mutations = {
    setUser(state, value) {
        if (value) {
            Object.assign(state, value);
        } else {
            Object.assign(state, getDefaultState());
        }
    },
    updateField,
};

const actions = {
    async loadUser({ commit }, data) {
        const res = await UserService.getUser(data.id);
        commit('setUser', res.data);
        return res.data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
