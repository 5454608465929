import { getField, updateField } from 'vuex-map-fields';
import CoursesService from '@/services/CoursesService';

const getDefaultState = function () {
    return {
        id: null,
        name: null,
        availableFrom: null,
        availableUntil: null,
        editorNote: null,
        sortOrder: null,
        userNote: null,
        status: null
    };
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = getDefaultState();

const getters = {
    getField,
    form: (state) => ({
        id: state.id,
        name: state.name,
        availableFrom: state.availableFrom,
        availableUntil: state.availableUntil,
        editorNote: state.editorNote,
        sortOrder: state.sortOrder,
        userNote: state.userNote,
        status: state.status,
    }),
};

const mutations = {
    setLectureTrackSubject(state, value) {
        if (value) {
            // value.Transfers.forEach((status) => {
            //     status.Events.sort((a, b) => new Date(a.Date) - new Date(b.Date));
            // });
            Object.assign(state, value);
        } else {
            Object.assign(state, getDefaultState());
        }
    },

    storeReportDetails(state, data) {
        Object.assign(state, data);
    },
    updateField,
};

const actions = {
    async loadLectureTrackSubject({ commit }, data) {
        const course = JSON.parse(localStorage.getItem('course'));
        const res = await CoursesService.getCourseLecturesLearningTrackSubjects(course.id, window.location.href.split('/').reverse()[1], {
            EntityId: data.id
        });
        commit('setLectureTrackSubject', res.data.items[0]);
        return res.data;
    },
    async loadLectureTrackSubjectAdmin({ commit }, data) {
        const res = await CoursesService.getReportAdmin(data.id);
        commit('setLectureTrackSubject', res.data);
        return res.data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
