<template>
  <div class="LayoutFull">
    <router-view />
  </div>
</template>

<script>
export default {
    name: 'LayoutInApp',
};
</script>
