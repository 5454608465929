/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const state = () => ({
    tabs: [],
});

const getters = {

};

const mutations = {
    setTabs(state, tabs) {
        state.tabs = tabs;
    },
};

const actions = {
    tabs({ commit }, tabs) {
        // set new tabs array
        commit('setTabs', tabs);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
