import axios from 'axios';

const BASE_URL = process.env.VUE_APP_APIURL;

const ping = () => axios.get(`${BASE_URL}/api/Account/Ping`);
const authorize = (model) => axios.post(`${BASE_URL}/Token`, model, {
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
});
const getRefreshToken = (refreshToken) => axios.post(`${BASE_URL}/refreshToken`, refreshToken);
const logout = () => axios.post(`${BASE_URL}/api/Account/Logout`);
const changePassword = () => axios.post(`${BASE_URL}/api/Account/ChangePassword`);
const setPassword = () => axios.post(`${BASE_URL}/api/Account/SetPassword`);
const resetUserPassword = (data) => axios.post(`${BASE_URL}/api/Account/ResetUserPassword`, data);
const forgotPassword = (data) => axios.post(`${BASE_URL}/api/Account/ForgotPassword`, data);
const resetPassword = (data) => axios.post(`${BASE_URL}/api/Account/ResetPassword`, data);
const register = (model) => axios.post(`${BASE_URL}/api/Account/Register`, model);

export default {
    ping,
    authorize,
    getRefreshToken,
    logout,
    changePassword,
    setPassword,
    resetUserPassword,
    forgotPassword,
    resetPassword,
    register,
};
