import { getField, updateField } from 'vuex-map-fields';
import LeasingCompaniesService from '@/services/LeasingCompaniesService';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = () => ({
    Profile: {
        AllowContractHtmlAggregation: null,
        City: null,
        HasLawyerTransferForm: null,
        HasPOA: null,
        HasTransferForm: null,
        HouseNumber: null,
        Id: null,
        IdNumber: null,
        ImageUrl: null,
        IsManaged: null,
        Name: null,
        Status: null,
        Street: null,
        ZipCode: null,
    },
    Client: {
        AllowContractHtmlAggregation: null,
        AllowsApproval: null,
        ClientId: null,
        Email: null,
        Fee: null,
        ForbidTransfer: null,
        HasClosingPage: null,
        HasDriversLicense: null,
        HasGeneralContractForm: null,
        HasLawyerTransferForm: null,
        HasOpeningPage: null,
        HasTransferForm: null,
        IdNumber: null,
        InternalClientId: null,
        Name: null,
    },
});

const getters = {
    getField,
    form: (state) => ({
            Email: state.Client.Email,
            Fee: state.Client.Fee,
            AllowsApproval: state.Client.AllowsApproval,
            InternalClientId: state.Client.InternalClientId,
            AllowContractHtmlAggregation: state.AllowContractHtmlAggregation,
            ForbidTransfer: state.Client.ForbidTransfer,
        }),
};

const mutations = {
    setClient(state, data) {
        Object.assign(state.Client, data);
    },
    setProfile(state, data) {
        Object.assign(state.Profile, data);
    },
    storeClientDetails(state, data) {
        Object.assign(state.Client, data);
    },
    updateField,
};

const actions = {
    async loadClient({ commit }, data) {
        const res = await LeasingCompaniesService.getClient(data.id);
        commit('setClient', res.data.Client);
        commit('setProfile', res.data.Profile);
        return res.data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
