import axios from 'axios';

const BASE_URL = process.env.VUE_APP_APIURL;

const get = (force) => {
    if (get.cache && !force) return Promise.resolve(get.cache);
    return axios.get(`${BASE_URL}/api/Vehicles`)
        .then((r) => {
            const cache = r.data.map((i) => ({ Value: i.Id, Text: i.Name }));
            get.cache = cache;
            return cache;
        })
        .catch((err) => console.log(err));
};
const allAdmin = (model, cancelToken) => axios.post(`${BASE_URL}/api/Vehicles/All`, model, {
    cancelToken,
});
const search = (model, cancelToken) => axios.post(`${BASE_URL}/api/Vehicles/Mine`, model, {
    cancelToken,
});
const searchPlateNumber = (term, cancelToken) => axios.get(`${BASE_URL}/api/Vehicles/Mine/PlateNumbers?term=${term}`, {
    cancelToken,
});
const searchAdminPlateNumber = (term, cancelToken) => axios.get(`${BASE_URL}/api/Vehicles/All/PlateNumbers?term=${term}`, {
    cancelToken,
});
const deleteContract = (id, contractId) => axios.delete(`${BASE_URL}/api/Vehicles/${id}/Contract/${contractId}`);
const addContract = (id, data) => axios.put(`${BASE_URL}/api/Vehicles/${id}/Contract`, data);
const unmanaged = (cancelToken) => axios.get(`${BASE_URL}/api/Vehicles/Mine/Unmanaged`, {
    cancelToken,
});
const unmanagedXls = (cancelToken) => axios.get(`${BASE_URL}/api/Vehicles/Mine/Unmanaged/Xlsx`, {
    cancelToken,
    responseType: 'blob',
});
const exportXls = (query, cancelToken) => axios.post(`${BASE_URL}/api/Vehicles/Mine/Xlsx`, query, {
    cancelToken,
    responseType: 'blob',
});
const exportAllXls = (query, cancelToken) => axios.post(`${BASE_URL}/api/Vehicles/All/Xlsx`, query, {
    cancelToken,
    responseType: 'blob',
});
const getOwners = (id) => axios.get(`${BASE_URL}/api/Vehicles/Mine/${id}/Owners`);
const getOwnersAdmin = (id) => axios.get(`${BASE_URL}/api/Vehicles/Admin/${id}/Owners`);
const getVehicle = (id) => axios.get(`${BASE_URL}/api/Vehicles/Mine/${id}`);
const getVehicleAdmin = (id) => axios.get(`${BASE_URL}/api/Vehicles/Admin/${id}`);
const getContract = (contractId) => axios.get(`${BASE_URL}/api/Vehicles/Contracts/${contractId}`);
const getDriverContracts = (id) => axios.get(`${BASE_URL}/api/Vehicles/Mine/${id}/DriverContracts`);
const getAdminDriverContracts = (id) => axios.get(`${BASE_URL}/api/Vehicles/Admin/${id}/DriverContracts`);
const addVehicle = (model) => axios.post(`${BASE_URL}/api/Vehicles`, model);
const getVehicleDetails = (plateNumber) => axios.get(`${BASE_URL}/api/Vehicles/data/${plateNumber}`);
const updateOwnership = (vehicleId, ownerId, model) => axios.put(`${BASE_URL}/api/Vehicles/Mine/${vehicleId}/Owners/${ownerId}`, model);
const updateOwnershipHistory = (vehicleId, ownerId, model) => axios.put(`${BASE_URL}/api/Vehicles/Admin/${vehicleId}/Owners/${ownerId}`, model);
const updateContract = (vehicleId, contractId, model) => axios.put(`${BASE_URL}/api/Vehicles/${vehicleId}/Contract/${contractId}`, model);
const update = (id, model) => axios.put(`${BASE_URL}/api/Vehicles/${id}`, model);
const getVehicleHistory = (id) => axios.get(`${BASE_URL}/api/Archive/Vehicles/${id}`);
const getVehicleContractHistory = (id) => axios.get(`${BASE_URL}/api/Archive/VehicleContracts/${id}`);
const getExcludedVehicles = () => axios.get(`${BASE_URL}/api/Profiles/My/ExcludedVehicles`);
const updateExcludedVehicles = (data) => axios.put(`${BASE_URL}/api/Profiles/My/ExcludedVehicles`, data);
const removeExcludedVehicles = (id, data) => axios.post(`${BASE_URL}/api/Profiles/${id}/ExcludedVehicles/Remove`, data);

export default {
    get,
    search,
    getVehicle,
    getOwners,
    searchPlateNumber,
    unmanaged,
    unmanagedXls,
    exportXls,
    exportAllXls,
    deleteContract,
    addContract,
    getContract,
    getDriverContracts,
    getAdminDriverContracts,
    addVehicle,
    getVehicleDetails,
    updateOwnership,
    update,
    updateContract,
    allAdmin,
    getVehicleAdmin,
    getOwnersAdmin,
    searchAdminPlateNumber,
    getVehicleHistory,
    getVehicleContractHistory,
    getExcludedVehicles,
    updateExcludedVehicles,
    removeExcludedVehicles,
    updateOwnershipHistory
};
